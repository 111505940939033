<template>
  <div class="container-fluid" style="margin-top: 71px; position: relative">
    <Plan ref="savePlanModal" @updatePlan="updatePlan" />
    <Toast
      v-if="toastData.show"
      :type="toastData.type"
      :message="toastData.message"
      :show="toastData.show"
      :time="10000"
      @clearToast="clearToast"
    />
    <div>
      <ul class="breadcrumb ProximaNovaRegular">
        <li>
          <span>Model Results</span>
        </li>
        <li>
          <span>Sale Statistics</span>
        </li>
        <li>
          <span>Future Planning</span>
        </li>
        <li class="ProximaNovaBold">Planning Results</li>
      </ul>
    </div>
    <div
      class="d-flex mt-4"
      :class="[expand ? 'justify-content-end' : 'justify-content-between']"
    >
      <div
        class="d-none  card arrow-card"
        :class="[expand ? 'arrow-card-collapse' : '']"
        @click="toggleExpand"
      >
        <i v-if="!expand" class="fas fa-arrow-left arrow"></i>
        <i v-else class="fas fa-arrow-right arrow-white"></i>
      </div>
      <div :class="[expand ? 'btn-group' : '']">
        <w-button
          :buttonText="'Future Planning'"
          @buttonClicked="$emit('aglieBlock')"
        ></w-button>
      </div>
    </div>

    <b-row>
      <b-col cols="12" md="4" :class="[expand ? 'd-none' : '']">
        <div class="card">
          <div class="card-body p-0">
            <div class="card-title">Prediction</div>
            <div class="mt-5">
              <w-tab
                :tabName="activePredictionTab"
                :tabs="predictionTab"
                @activeTab="changePredictionTab($event)"
              >
              </w-tab>
            </div>
            <b-container fluid>
              <SalesGrowth
                :bigRangeSize="true"
                v-if="activePredictionTab === 'Revenue Based'"
                :sliderValue="sliderRevenueValue"
                :data="revenueData"
              />
              <SalesGrowth
                :bigRangeSize="true"
                v-if="activePredictionTab === 'Budget Based'"
                :sliderValue="sliderBudgetValue"
                :data="budgetData"
              />
            </b-container>
          </div>
        </div>
        <div class="card mt-5 mb-5 mb-md-0">
          <div class="card-body p-0">
            <div class="row">
              <div class="col-md-12">
                <details class="">
                  <Summary class="Additional-details"
                    >Additional Details</Summary
                  >
                  <b-container fluid>
                    <div
                      v-for="(detail, index) in additionalDetails"
                      :key="index"
                      class="row additonal-detail py-5 px-4"
                      :class="[!detail.select ? 'border-top' : 'detail-bg']"
                    >
                      <b-col cols="10">
                        <div class="details-heading">{{ detail.name }}</div>
                        <div v-if="detail.select">
                          <label>Product</label>
                          <b-form-select
                            v-model="detail.product"
                            :options="detail.dropdownList"
                          ></b-form-select>
                          <label class="mb-0">Date</label>
                          <div>
                            <w-datepicker
                              :placeHolder="'Select'"
                              :underLine="true"
                              :labelText="''"
                              @input="selectedDate(index, $event)"
                            ></w-datepicker>
                          </div>
                          <label>Value in numbers</label>
                          <b-form-input
                            v-model="detail.value"
                            type="number"
                          ></b-form-input>
                        </div>
                      </b-col>
                      <b-col cols="2">
                        <input
                          :name="`checkbox-${index}`"
                          type="checkbox"
                          class="form-check-input"
                          :value="detail.select"
                          @input="updateCheckbox(index, $event.target.checked)"
                        />
                      </b-col>
                    </div>
                  </b-container>
                </details>
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12" :md="expand ? 12 : 8">
        <div class="card mb-4">
          <div class="card-body p-0">
            <div class="card-title">Prediction</div>
            <b-container fluid class="wrapper-statistics">
              <b-row
                class="content-center pl-5"
                style="justify-content: initial;"
              >
                <div
                  v-for="(data, index) in predictionRowData"
                  :key="index"
                  class="item"
                >
                  <Statistics
                    :data="data"
                    :specialChar="false"
                    :specialCharText="''"
                    :img="data.image"
                    :index="index"
                  />
                  <div
                    v-if="
                      (!expand && (index + 1) % 3 !== 0) ||
                        (expand && (index + 1) % 6 !== 0)
                    "
                    class="item-right-line"
                  ></div>
                </div>
              </b-row>
              <b-row
                class="extra-wrapper-statistics content-center pl-5"
                style="justify-content: initial;"
              >
                <div
                  v-for="(data, index) in row1Data"
                  :key="index + 'a'"
                  class="item"
                >
                  <Statistics
                    :data="data"
                    :index="index"
                    :img="data.image"
                    :specialChar="false"
                    :specialCharText="''"
                  />
                  <div
                    v-if="
                      (!expand && (index + 1) % 3 !== 0) ||
                        (expand && (index + 1) % 6 !== 0)
                    "
                    class="item-right-line"
                  ></div>
                </div>
                <div
                  v-for="(data, index) in row2Data"
                  :key="index + 'b'"
                  class="item"
                >
                  <Statistics
                    :data="data"
                    :index="index"
                    :img="data.image"
                    :specialChar="false"
                    :specialCharText="''"
                  />
                  <div
                    v-if="
                      (!expand && (index + 1) % 3 !== 0) ||
                        (expand && (index + 1) % 6 !== 0)
                    "
                    class="item-right-line"
                  ></div>
                </div>
                <div
                  v-for="(data, index) in row3Data"
                  :key="index"
                  class="item"
                >
                  <Statistics
                    :data="data"
                    :index="index"
                    :img="data.image"
                    :specialChar="false"
                    :specialCharText="''"
                  />
                  <div
                    v-if="
                      (!expand && (index + 1) % 3 !== 0) ||
                        (expand && (index + 1) % 6 !== 0)
                    "
                    class="item-right-line"
                  ></div>
                </div>
              </b-row>
            </b-container>
          </div>
        </div>
        <div class="card mb-4">
          <div class="card-body p-3">
            <ColumnChart :chartData="columnChartData" />
          </div>
        </div>
        <div class="card mb-4">
          <div class="card-body pt-2">
            <w-chart-data
              :expandData="expandDigitlData"
              :dropdownList="digitalDropdownList"
              :selectedOption="digitalTitle"
              :chartData="digitalData"
              @chartClick="$emit('clickedChart', $event)"
              @selectedOption="$emit('changeDigitalDropdown',$event)"
              :text="'Digital'"
            >
            </w-chart-data>
          </div>
        </div>
         <div class="card mb-4">
          <div class="card-body pt-2">
            <w-chart-data
              :expandData="expandContentData"
              :dropdownList="contentDropdownList"
              :selectedOption="contentTitle"
              :chartData="contentData"
              @chartClick="$emit('clickedContentChart', $event)"
              @selectedOption="$emit('changeContentDropdown',$event)"
              :text="'Content'"
            >
            </w-chart-data>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Tab from "@/components/Solutions/Tab.vue";
import SalesGrowth from "@/components/Solutions/SalesGrowth.vue";
import ColumnChart from "@/components/Chart/ColumnChart.vue";
import Plan from "@/components/Modal/Plan.vue";
import Toast from "@/components/Toast/Toast.vue";
import DatePicker from "@/widgets/DatePicker.vue";
import Statistics from "@/components/AgileStatistics.vue";
import Button from "@/components/Profile/Button.vue";
import ChartWiseData from "@/components/Summary/ChannelWiseData.vue";
export default {
  name: "PlanningResults",
  props:{
    predictionRowData:{
      type: Array,
      default: ()=> []
    },
    row1Data:{
      type: Array,
      default: ()=> []
    },
    row2Data:{
      type: Array,
      default: ()=> []
    },
    row3Data:{
      type: Array,
      default: ()=> []
    },
    columnChartData:{
      type: Object,
      default: ()=> {}
    },
    digitalData:{
      type: Object,
      default: ()=> {}
    },
    digitalDropdownList:{
      type: Array,
      default: ()=>[]
    },
    digitalTitle:{
      type: Object,
      default: ()=>{}
    },
    expandDigitlData:{
      type: Object,
      default: ()=>{}
    },
    contentData:{
      type: Object,
      default: ()=> {}
    },
    contentDropdownList:{
      type: Array,
      default: ()=>[]
    },
    contentTitle:{
      type: Object,
      default: ()=>{}
    },
    expandContentData:{
      type: Object,
      default: ()=>{}
    }
  },
  components: {
    "w-tab": Tab,
    "w-chart-data": ChartWiseData,
    "w-datepicker": DatePicker,
    SalesGrowth,
    ColumnChart,
    Plan,
    Toast,
    Statistics,
    "w-button": Button,
  },
  data() {
    return {
      expand: true,
      predictionTab: [
        {
          category: "Revenue Based",
        },
        {
          category: "Budget Based",
        },
      ],
      activePredictionTab: "Revenue Based",
      sliderRevenueValue: 16,
      revenueData: [{ name: "Expected Revenue", value: 621 }],
      sliderBudgetValue: 46,
      budgetData: [{ name: "Expected Budget", value: 621 }],
      digitalExpandData: [
        {
          name: "Video Campaigns",
          pointWidth: 20,
          data: [
            {
              x: Date.UTC(2014, 10, 21),
              x2: Date.UTC(2014, 11, 2),
              y: 0,
              pointWidth: 16,
              pointPadding: 0,
              legendColor: "black",
            },
          ],
          dataLabels: {
            enabled: false,
          },
        },
        {
          name: "Engagement Campaigns",
          pointWidth: 20,
          data: [
            {
              x: Date.UTC(2014, 11, 2),
              x2: Date.UTC(2014, 11, 10),
              y: 1,
              pointWidth: 16,
              pointPadding: 0,
            },
          ],
          dataLabels: {
            enabled: false,
          },
        },
        {
          name: "Lead Ads",
          pointWidth: 20,
          data: [
            {
              x: Date.UTC(2014, 11, 10),
              x2: Date.UTC(2014, 11, 23),
              y: 2,
              pointWidth: 16,
              pointPadding: 0,
            },
          ],
          dataLabels: {
            enabled: false,
          },
        },
        {
          name: "Click to website",
          pointWidth: 20,
          color: "red",
          data: [
            {
              x: Date.UTC(2014, 11, 23),
              x2: Date.UTC(2014, 11, 30),
              y: 3,
              pointWidth: 16,
              pointPadding: 0,
            },
          ],
          dataLabels: {
            enabled: false,
          },
        },
      ],
      predictionData: [
        {
          label: "Annual Target",
          value: "568",
          specialChar: true,
          image: "click.svg",
        },
        {
          label: "Yearly Budget",
          value: "768",
          specialChar: true,
          image: "file-paper-fill.svg",
        },
        {
          label: "Growth",
          value: "10.0",
          specialChar: false,
          image: "funds-fill.svg",
        },
      ],
      lineChartData: {
        series: [
          {
            data: [
              { x: Date.UTC(2020, 1, 31), y: 1 },
              { x: Date.UTC(2020, 2, 31), y: 3 },
              { x: Date.UTC(2020, 3, 31), y: 15 },
              { x: Date.UTC(2020, 4, 31), y: 4 },
              { x: Date.UTC(2020, 5, 31), y: 15 },
              { x: Date.UTC(2020, 6, 31), y: 14 },
              { x: Date.UTC(2020, 7, 31), y: 23 },
              { x: Date.UTC(2020, 8, 31), y: 42 },
              { x: Date.UTC(2020, 9, 31), y: 25 },
            ],
            marker: {
              symbol: "circle",
              radius: 10,
              status: {
                hover: {
                  enabled: true,
                },
              },
            },
            type: "spline",
            name: "Previous Spend",
          },
          {
            data: [
              { x: Date.UTC(2020, 1, 31), y: 10 },
              { x: Date.UTC(2020, 2, 31), y: 3 },
              { x: Date.UTC(2020, 3, 31), y: 55 },
              { x: Date.UTC(2020, 4, 31), y: 4 },
              { x: Date.UTC(2020, 5, 31), y: 1 },
              { x: Date.UTC(2020, 6, 31), y: 40 },
              { x: Date.UTC(2020, 7, 31), y: 27 },
              { x: Date.UTC(2020, 8, 31), y: 7 },
              { x: Date.UTC(2020, 9, 31), y: 2 },
            ],
            marker: {
              symbol: "circle",
              radius: 10,
            },
            type: "spline",
            name: "Predicted Spend",
          },
        ],
        title: {
          text: "Media - Previous vs Predicted Budget",
          align: "left",
          margin: 60,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        subtitle: {
          text: "Recommended Budget Mix",
          align: "left",
          y: 60,
          style: {
            color: "#8394b4",
            fontSize: "20px",
            fontFamily: "ProximaNovaRegular",
          },
        },
        legend: {
          align: "left",
          verticalAlign: "bottom",
          symbolPadding: 15,
          symbolWidth: 7,
          symbolHeight: 40,
          itemStyle: {
            color: "#8394b4",
            fontSize: "16px",
          },
        },
        yAxis: {
          title: {
            text: "sales growth %",
            style: {
              color: "#8394b4",
            },
          },
        },
        xAxis: {
          title: {
            text: "",
            style: {
              color: "#8394b4",
            },
          },
          type: "datetime",
        },
        colors: ["#050505", "#18ffff"],
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
          formatter: function() {
            const x = this.x;
            var value = this.series.data.find((obj) => obj.category === x)
              .options.y;
            if (this.series.name) {
              return ` ${this.series.name}
              : <b>
            ${value}
              </b>`;
            } else {
              return value;
            }
          },
        },
      },
      additionalDetails: [
        {
          name: "Product",
          product: "",
          dropdownList: [
            { text: "New packing", id: "New packing" },
            { text: "New product launch", id: "New product launch" },
            { text: "New product variant", id: "New product variant" },
          ],
          select: false,
          date: "",
          value: "",
        },
        {
          name: "Distribution",
          dropdownList: [
            { text: "New packing", id: "New packing" },
            { text: "New product launch", id: "New product launch" },
            { text: "New product variant", id: "New product variant" },
          ],
          select: false,
          product: "",
          date: "",
          value: "",
        },
        {
          name: "Consumer Promo",
          dropdownList: [
            { text: "New packing", id: "New packing" },
            { text: "New product launch", id: "New product launch" },
            { text: "New product variant", id: "New product variant" },
          ],
          select: false,
          product: "",
          date: "",
          value: "",
        },
        {
          name: "Advertising",
          dropdownList: [
            { text: "New packing", id: "New packing" },
            { text: "New product launch", id: "New product launch" },
            { text: "New product variant", id: "New product variant" },
          ],
          select: false,
          product: "",
          date: "",
          value: "",
        },
      ],
      toastData: {
        show: false,
        type: "",
        message: "",
      },
    };
  },
  mounted() {
    console.log(Date.UTC(2014, 11, 2) - Date.UTC(2014, 10, 21));
  },
  methods: {
    getDataBreakdown(data) {
      console.log(data);
    },
    changePredictionTab(tabName) {
      this.activePredictionTab = tabName;
    },
    toggleExpand() {
      this.expand = !this.expand;
    },
    updateCheckbox(index, val) {
      this.additionalDetails[index].select = val;
    },
    clearToast() {
      this.toastData = {
        show: false,
        type: "",
        message: "",
      };
    },
    updatePlan() {
      this.toastData = {
        show: true,
        type: "success",
        message: "Yay, our system has saved your plan successfully",
      };
    },
    selectedDate(index, e) {
      this.additionalDetails[index].date = e;
    },
  },
};
</script>

<style scoped>
.card {
  box-shadow: 0 0 8px 0 #c9ced5;
}
.card-title {
  margin: 24px 0px 0px 24px;
  font-family: ProximaNovaBold;
  font-size: 20px;
  color: #222a37;
}
.card-body {
  padding: 24px;
}

.arrow-card {
  width: 74px;
  height: 74px;
  padding: 25px;
  box-shadow: 0 0 8px 0 #c9ced5;
  margin-top: 17px;
}
.row > * {
  width: unset;
}
.arrow-card-collapse {
  background-color: black;
  position: absolute;
  left: 0;
  margin-top: 0px;
}

.arrow {
  font-size: 18px;
}

.arrow-white {
  color: #fff;
  font-size: 18px;
}

.btn-group {
  margin-bottom: 31px;
}

.btn-effect {
  width: 183px;
  height: 60px;
  font-family: ProximaNovaRegular;
  border-radius: 3px;
  background-color: #050505;
  border: solid 2px #050505;
  font-size: 18px;
  color: #fff;
}

.btn-effect-outline {
  max-width: 183px;
  height: 60px;
  border-radius: 3px;
  border: solid 2px #050505;
  font-family: ProximaNovaBold;
  font-size: 18px;
  color: #222a37;
  background-color: transparent;
}

.wrapper-statistics {
  padding: 0px 16px;
}

.extra-wrapper-statistics {
  border-top: 1px solid #e8edf5;
  background-color: #f6faff;
}

.item {
  display: flex;
  margin: 20px 0px;
  align-items: center;
  width: 33%;
}

/* .item:not(:nth-child(3n)):before {
  content: "";
  position: absolute;
  height: 40px;
  width: 1px;
  background: #e8edf5;
  right: 20px;
  top: 8px;
} */

.item-right-line {
  position: absolute;
  height: 40px;
  width: 1px;
  background: #e8edf5;
  right: 20px;
}

.item-img {
  background: #67e8ff;
  border-radius: 50%;
  padding: 2rem;
  height: 56px;
  width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.item-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 24px;
}

.item-text__title {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8394b4;
  padding-bottom: 1rem;
}

.item-text__subtitle {
  font-family: ProximaNovaBold;
  font-size: 28px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #313846;
}

.blue-pastel {
  background: #67e8ff;
}

.green-pastel {
  background: #81f89e;
}

.pink-pastel {
  background: #faacfe;
}

.red-pastel {
  background: #ffa2a2;
}

.yellow-pastel {
  background: #ffdd7c;
}

.Additional-details {
  width: 259px;
  margin: 1px 986px 2px 0;
  font-family: ProximaNovaBold;
  font-size: 20px;
  color: #222a37;
  padding: 28px 24px;
}

.details-heading {
  font-family: ProximaNovaBold;
  font-size: 20px;
  color: #222a37;
}

.form-check-input {
  height: 24px;
  position: relative;
  top: 0px;
  margin: 0px;
  margin-right: 10px;
}

.form-check-input:checked {
  background-color: #222a37;
  border-color: #222a37;
}
label {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  color: #9aafd4;
  margin-top: 37px;
}
input[type="number"] {
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  box-shadow: none;
  color: #222a37;
  font-family: ProximaNovaBold;
  font-size: 16px;
  border-radius: 0;
  background-color: #eff5ff;
}

/* input[type="text"] {
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  box-shadow: none;
  color: #222a37;
  font-family: ProximaNovaBold;
  font-size: 16px;
  border-radius: 0;
  width: 100%;
  background-color: #eff5ff;
} */
select {
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  box-shadow: none;
  color: #222a37;
  font-family: ProximaNovaBold;
  font-size: 16px;
  border-radius: 0;
  padding-left: 0;
  background-color: #eff5ff;
}
>>> option {
  color: #222a37;
  font-family: ProximaNovaRegular;
  font-size: 16px;
}
select:focus {
  border: none;
  border-bottom: 1px solid black;
  box-shadow: none;
  outline: none;
}
.detail-bg {
  background-color: #eff5ff;
}
</style>
